import React, {useEffect, useState} from "react";
import 'swiper/css';
import '../../css/index.css';

import kd_1 from "../../../assets/teen/KdSixteen/kd_1.png";
import kd_2 from "../../../assets/teen/KdSixteen/kd_2.png";
import kd_3 from "../../../assets/teen/KdSixteen/kd_3.png";
import kd_4 from "../../../assets/teen/KdSixteen/kd_4.png";
import kd_5 from "../../../assets/teen/KdSixteen/kd_5.png";
import kd_6 from "../../../assets/teen/KdSixteen/kd_6.png";
import kd_7 from "../../../assets/teen/KdSixteen/kd_7.png";
import kd_8 from "../../../assets/teen/KdSixteen/kd_8.png";
import kd_9 from "../../../assets/teen/KdSixteen/kd_9.png";
import kd_10 from "../../../assets/teen/KdSixteen/kd_10.png";
import kd_11 from "../../../assets/teen/KdSixteen/kd_11.png";
import kd_12 from "../../../assets/teen/KdSixteen/kd_12.png";
import kd_13 from "../../../assets/teen/KdSixteen/kd_13.png";

import kd_ones from "../../../assets/teen/KdSixteen/kd_one.gif";
import kd_two from "../../../assets/teen/KdSixteen/kd_two.gif";
import kd_three from "../../../assets/teen/KdSixteen/kd_three.gif";
import kd_four from "../../../assets/teen/KdSixteen/kd_four.gif";
import kd_five from "../../../assets/teen/KdSixteen/kd_five.gif";
import kd_six from "../../../assets/teen/KdSixteen/kd_six.gif";
import kd_seven from "../../../assets/teen/KdSixteen/kd_seven.gif";

const PageKdSixteen = () => {
    const [ratio, setRatio] = useState(null);
    const imgData = [kd_1, kd_2, kd_3, kd_4, kd_5, kd_6, kd_7, kd_8, kd_9, kd_10, kd_11, kd_12, kd_13, kd_ones, kd_two, kd_three, kd_four, kd_five, kd_six, kd_seven]
    const gifTopData = ['37%', '12%', '6%', '52.4%', '32.8%', '11.2%', '11.2%']
    const gifLeftData = ['8.082%', '15.775%', '38.845%', '43.705%', '46.545%', '55.48%', '57.855%']

    const resizeUpdate = (e) => {
        let wdr = Math.round(e.target.innerWidth / 2400 * 1000) / 1000;
        setRatio(wdr)
    };

    useEffect(() => {
        document.title = '选你所想、畅享补能'
        let wdr = Math.round(window.innerWidth / 2400 * 1000) / 1000;
        setRatio(wdr)
        window.addEventListener('resize', resizeUpdate);
        return () => {
            window.removeEventListener('resize', resizeUpdate);
        }
    }, [])

    useEffect(() => {
        ratio && onLoadImg(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ratio])

    function onLoadImg(key) {
        let imgDoc = document.getElementsByTagName("img");
        if (key === imgDoc.length) return;
        imgDoc[key].src = imgData[key];
        if (key > 12) {
            imgDoc[key].style.width = 640 * ratio + 'px';
            imgDoc[key].style.height = 360 * ratio + 'px';
            imgDoc[key].style.position = 'absolute';
            imgDoc[key].style.left = gifLeftData[key - 13];
            imgDoc[key].style.top = gifTopData[key - 13];
        } else {
            imgDoc[key].style.width = 2400 * ratio + 'px';
        }
        imgDoc[key].onload = function () {
            console.log(key + '加载完成')
            onLoadImg(++key)
        }
    }

    return (
        <div className={'sixteen-content'} style={{width: 31200 * ratio + 'px', height: 990 * ratio + 'px'}}>
            {imgData.map((item, index) => (
                <img key={index} className={'page-swiper-img'} alt="kd_one"/>
            ))}
        </div>
    );
}

export default PageKdSixteen;

// import React, {useEffect, useState} from "react";
// import 'swiper/css';
// import '../css/index.css';
// import kd_one from "../../assets/KdSixteen/kd_one.png";
// import kd_ones from "../../assets/KdSixteen/kd_one.gif";
// import kd_two from "../../assets/KdSixteen/kd_two.gif";
// import kd_three from "../../assets/KdSixteen/kd_three.gif";
// import kd_four from "../../assets/KdSixteen/kd_four.gif";
// import kd_five from "../../assets/KdSixteen/kd_five.gif";
// import kd_six from "../../assets/KdSixteen/kd_six.gif";
// import kd_seven from "../../assets/KdSixteen/kd_seven.gif";
//
// const PageKdSixteen = () => {
//     const [ratio, setRatio] = useState(null);
//     const resizeUpdate = (e) => {
//         let wdr = Math.round(e.target.innerWidth / 2400 * 1000) / 1000;
//         setRatio(wdr)
//     };
//
//     useEffect(() => {
//         document.title = '选你所想、畅享补能'
//         let wdr = Math.round(window.innerWidth / 2400 * 1000) / 1000;
//         setRatio(wdr)
//         window.addEventListener('resize', resizeUpdate);
//         return () => {
//             window.removeEventListener('resize', resizeUpdate);
//         }
//     }, [])
//
//     return (
//         <div className={'sixteen-content'} style={{width: 31200 * ratio + 'px', height: 990 * ratio + 'px'}}>
//
//             <img src={kd_one} className={'page-swiper-img'} alt="kd_one"/>
//             <img src={kd_ones} className={'sixteen-gif-one'} width={640 * ratio + 'px'} height={360 * ratio + 'px'} alt="kd_ones"/>
//             <img src={kd_two} className={'sixteen-gif-two'} width={640 * ratio + 'px'} height={360 * ratio + 'px'} alt="kd_two"/>
//             <img src={kd_three} className={'sixteen-gif-three'} width={640 * ratio + 'px'} height={360 * ratio + 'px'} alt="kd_three"/>
//             <img src={kd_four} className={'sixteen-gif-four'} width={640 * ratio + 'px'} height={360 * ratio + 'px'} alt="kd_four"/>
//             <img src={kd_five} className={'sixteen-gif-five'} width={640 * ratio + 'px'} height={360 * ratio + 'px'} alt="kd_five"/>
//             <img src={kd_six} className={'sixteen-gif-six'} width={640 * ratio + 'px'} height={360 * ratio + 'px'} alt="kd_six"/>
//             <img src={kd_seven} className={'sixteen-gif-seven'} width={640 * ratio + 'px'} height={360 * ratio + 'px'} alt="kd_seven"/>
//         </div>
//     );
// }
//
// export default PageKdSixteen;
