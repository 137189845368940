import React, {useEffect} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import '../../css/index.css';
import "swiper/css/effect-creative";
import kd_one from '../../../assets/twenty/KdTwentyFive/kd_one.jpg';
import kd_two from '../../../assets/twenty/KdTwentyFive/kd_two.jpg';
import kd_three from '../../../assets/twenty/KdTwentyFive/kd_three.jpg';
import kd_four from '../../../assets/twenty/KdTwentyFive/kd_four.jpg';
import kd_five from '../../../assets/twenty/KdTwentyFive/kd_five.jpg';
import kd_six from '../../../assets/twenty/KdTwentyFive/kd_six.jpg';
import kd_seven from '../../../assets/twenty/KdTwentyFive/kd_seven.jpg';
import kd_eight from '../../../assets/twenty/KdTwentyFive/kd_eight.jpg';
import kd_nine from '../../../assets/twenty/KdTwentyFive/kd_nine.jpg';
import kd_ten from '../../../assets/twenty/KdTwentyFive/kd_ten.jpg';
import {EffectCreative} from "swiper";

const PageKdTwentyFive = () => {

    useEffect(() => {
        document.title = '锐歌'
    }, [])

    return (
        <Swiper
                autoHeight
                loop
                spaceBetween={10}
                slidesPerView={1}
                effect={"creative"}
                modules={[EffectCreative]}
                creativeEffect={{
                    prev: {
                        shadow: true,
                        origin: "left center",
                        translate: ["-5%", 0, -200],
                        rotate: [0, 100, 0],
                    },
                    next: {
                        origin: "right center",
                        translate: ["5%", 0, -200],
                        rotate: [0, -100, 0],
                    },
                }}>
            <SwiperSlide><img src={kd_one} className={'page-swiper-img'} alt="kd_one"/></SwiperSlide>
            <SwiperSlide><img src={kd_two} className={'page-swiper-img'} alt="kd_two"/></SwiperSlide>
            <SwiperSlide><img src={kd_three} className={'page-swiper-img'} alt="kd_three"/></SwiperSlide>
            <SwiperSlide><img src={kd_four} className={'page-swiper-img'} alt="kd_four"/></SwiperSlide>
            <SwiperSlide><img src={kd_five} className={'page-swiper-img'} alt="kd_four"/></SwiperSlide>
            <SwiperSlide><img src={kd_six} className={'page-swiper-img'} alt="kd_four"/></SwiperSlide>
            <SwiperSlide><img src={kd_seven} className={'page-swiper-img'} alt="kd_four"/></SwiperSlide>
            <SwiperSlide><img src={kd_eight} className={'page-swiper-img'} alt="kd_four"/></SwiperSlide>
            <SwiperSlide><img src={kd_nine} className={'page-swiper-img'} alt="kd_four"/></SwiperSlide>
            <SwiperSlide><img src={kd_ten} className={'page-swiper-img'} alt="kd_four"/></SwiperSlide>
        </Swiper>
    );
}

export default PageKdTwentyFive;
