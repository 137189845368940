import React, {useEffect, useRef, useState} from "react";
import '../../css/index.css';
import kd_two from '../../../assets/ten/KdTwo/kd_two.mp4';
import kd_play from '../../../assets/images/play.png';
import kd_pause from '../../../assets/images/pause.png';
import kd_preview from '../../../assets/ten/KdTwo/preview.png';

const PageKdTwo = () => {
    const videoRef = useRef(null);
    const [status, setStatus] = useState('play')
    const [showBtn, setShowBtn] = useState(true)

    useEffect(() => {
        document.title = '创新不止、勇敢如初'
        const pauseFun = () => setStatus('play');
        videoRef.current.addEventListener('pause', pauseFun);
        const playFun = () => setStatus('pause');
        videoRef.current.addEventListener('play', playFun);
        const endFun = () => setStatus('play');
        videoRef.current.addEventListener('ended', endFun);

        // 取消监听
        return () => {
            videoRef.current.removeEventListener('pause', pauseFun);
            videoRef.current.removeEventListener('play', playFun);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            videoRef.current.removeEventListener('ended', endFun);
        }
    }, [])

    useEffect(() => {
        if (status === 'play') return;
        let timeChange = setTimeout(() => {
            setShowBtn(false)
            clearTimeout(timeChange)
        }, 3000)
    }, [status]);

    return (
        <div className={'two-video-content'}>
            <video ref={videoRef} autoPlay playsInline onClick={() => setShowBtn(true)}
                   poster={kd_preview} style={{display: 'block'}} width={'100%'} height={'100%'} src={kd_two}/>
            {showBtn && status === 'play' &&
                <img src={kd_play} className={'two-swiper-play'} alt="kd_play" onClick={() => videoRef.current.play()}/>}
            {showBtn && status === 'pause' &&
                <img src={kd_pause} className={'two-swiper-play'} alt="kd_pause" onClick={() => videoRef.current.pause()}/>}
        </div>

    );
}

export default PageKdTwo;
