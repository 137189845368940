import React from "react";
import '../../css/index.css';
import kd_porter from '../../../assets/fifty/KdFiftyFour/kd_porter.png';
import kd_video from "../../../assets/fifty/KdFiftyFour/kd_mp4.mov";
import CustomVideo from "../../conponents/CustomVideo";

const PageKdFiftyFour = () => {

    return <CustomVideo kd_porter={kd_porter} kd_video={kd_video} title={'XT6 座座皆豪华'}/>
}

export default PageKdFiftyFour;
