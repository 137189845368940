import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/seventy/KdSeventyOne/kd_one.jpg';
import kd_two from '../../../assets/seventy/KdSeventyOne/kd_two.jpg';
import kd_three from '../../../assets/seventy/KdSeventyOne/kd_three.jpg';
import kd_video from "../../../assets/seventy/KdSeventyOne/kd_mp4.mp4";
import kd_poster from "../../../assets/seventy/KdSeventyOne/kd_poster.jpg";
import kd_close from "../../../assets/images/video_close.png";
import CustomSwiperTwo from "../../conponents/CustomSwiperTwo";

const PageKdSeventyOne = () => {
    const data = [{
        videoFill: true,
        src: kd_one,
        videoShow: true,
        videoPoster: kd_poster,
        videoSrc: kd_video,
        videoClassName: 'page-video-sixty',
        closeSrc: kd_close,
        closeClassName: 'page-video-close-sixtyone'
    }, {src: kd_two}, {src: kd_three}]

    return <CustomSwiperTwo data={data}/>
}

export default PageKdSeventyOne;
