import React, {useEffect} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import '../../css/index.css';
import kd_one from '../../../assets/ten/KdEight/kd_one.png';

const PageKdEight = () => {

    useEffect(() => {
        document.title = 'IQ锦鲤'
    }, [])

    return (
        <Swiper autoHeight slidesPerView={1}>
            <SwiperSlide><img src={kd_one} className={'page-swiper-img'} alt="kd_three"/></SwiperSlide>
        </Swiper>
    );
}

export default PageKdEight;
