import React, {useEffect, useRef, useState} from "react";
import kd_play from "../../../assets/images/play.png";
import kd_pause from "../../../assets/images/pause.png";

const CustomVideo = (props) => {
    const {kd_porter, kd_video, title = '锐歌百科'} = props
    const videoRef = useRef(null);
    const videoBtnRef = useRef(0);
    const [status, setStatus] = useState('play')
    const [showBtn, setShowBtn] = useState(true)
    const [showPorter, setShowPorter] = useState(true)

    useEffect(() => {
        document.title = title;
        const pauseFun = () => {
            setShowPorter(false)
            setStatus('play');
        }
        videoRef.current.addEventListener('pause', pauseFun);
        const playFun = () => setStatus('pause');
        videoRef.current.addEventListener('play', playFun);
        const endFun = () => {
            setStatus('play');
            setShowBtn(true)
            setShowPorter(true)
        }
        videoRef.current.addEventListener('ended', endFun);
        return () => {
            videoRef.current.removeEventListener('pause', pauseFun);
            videoRef.current.removeEventListener('play', playFun);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            videoRef.current.removeEventListener('ended', endFun);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (status === 'play') return;
        let timeChange = setTimeout(() => {
            setShowBtn(false)
            clearTimeout(timeChange)
        }, 1000)
    }, [status]);

    function onVideo(isPlay) {
        setShowPorter(false)
        if (isPlay) {
            videoRef.current.play()
        } else {
            videoBtnRef.current && clearTimeout(videoBtnRef.current)
            videoRef.current.pause()
        }
    }

    function closeBtn() {
        setShowBtn(true)
        videoBtnRef.current = setTimeout(() => {
            setShowBtn(false)
            clearTimeout(videoBtnRef.current)
        }, 2500)
    }

    return (
        <div className={'page-col'}>
            <div className={'page-col-box'}>
                <video
                    className={'page-col-video'}
                    playsInline
                    ref={videoRef}
                    src={kd_video}
                    onClick={() => closeBtn()}
                >抱歉，您的浏览器不支持内嵌视频</video>
            </div>
            {showPorter && <img src={kd_porter} className={'page-col-poster'} alt="封面图"/>}
            {showBtn && status === 'play' && <img src={kd_play} className={'page-col-play'} alt="播放" onClick={() => onVideo(true)}/>}
            {showBtn && status === 'pause' && <img src={kd_pause} className={'page-col-pause'} alt="暂停" onClick={() => onVideo()}/>}
        </div>
    )
}

export default CustomVideo;
