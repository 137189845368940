import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/seventy/KdSeventyTwo/kd_one.jpg';
import kd_video from "../../../assets/seventy/KdSeventyTwo/kd_mp4.mp4";
// import kd_poster from "../../../assets/sixty/KdSixtySeven/kd_poster.png";
import kd_close from "../../../assets/images/video_close.png";
import CustomSwiperTwo from "../../conponents/CustomSwiperTwo";

const PageKdSeventyTwo = () => {
    const data = [{
        videoFill: true,
        src: kd_one,
        videoShow: true,
        // videoPoster: kd_poster,
        videoSrc: kd_video,
        videoClassName: 'page-video-seventytwo',
        closeSrc: kd_close,
        closeClassName: 'page-video-close-sixtyone'
    }]

    return <CustomSwiperTwo data={data}/>
}

export default PageKdSeventyTwo;
