import React, {useEffect} from "react";
import 'swiper/css';
import '../../css/index.css';
import kd_one from '../../../assets/ten/KdTen/kd_one.mp4';

const PageKdTen = () => {

    useEffect(() => {
        document.title = '世界杯冷知识'
    }, [])

    return (
        <video loop muted autoPlay style={{display:'block'}} width={'100%'} height={'100%'} src={kd_one}/>
    );
}

export default PageKdTen;

// import React, {useEffect} from "react";
// import {Swiper, SwiperSlide} from 'swiper/react';
// import 'swiper/css';
// import '../css/index.css';
// import kd_one from '../../assets/KdTen/kd_one.gif';
//
// const PageKdTen = () => {
//
//     useEffect(() => {
//         document.title = '世界杯冷知识'
//     }, [])
//
//     return (
//         <Swiper autoHeight slidesPerView={1}>
//             <SwiperSlide><img src={kd_one} className={'page-swiper-img'} alt="kd_three"/></SwiperSlide>
//         </Swiper>
//     );
// }
//
// export default PageKdTen;
