import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/forty/KdForty/kd_one.jpg';
import kd_two from '../../../assets/forty/KdForty/kd_two.jpg';
import kd_three from '../../../assets/forty/KdForty/kd_three.jpg';
import kd_four from '../../../assets/forty/KdForty/kd_four.jpg';
import kd_video from "../../../assets/forty/KdForty/kd_mp4.mp4";
import kd_poster from "../../../assets/forty/KdForty/kd_poster.jpg";
import CustomSwiper from "../../conponents/CustomSwiper";

const PageKdForty = () => {
    const data = [{
        src: kd_one,
        videoShow: true,
        videoPoster: kd_poster,
        videoSrc: kd_video,
        videoClassName: 'page-video-twentyseven'
    }, {src: kd_two}, {src: kd_three}, {src: kd_four}]

    return <CustomSwiper data={data}/>
}

export default PageKdForty;
