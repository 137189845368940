import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/thirty/KdThirty/kd_one.jpg';
import kd_two from '../../../assets/thirty/KdThirty/kd_two.jpg';
import kd_three from '../../../assets/thirty/KdThirty/kd_three.jpg';
import kd_four from '../../../assets/thirty/KdThirty/kd_four.jpg';
import kd_five from '../../../assets/thirty/KdThirty/kd_five.jpg';
import kd_six from '../../../assets/thirty/KdThirty/kd_six.jpg';
import kd_seven from '../../../assets/thirty/KdThirty/kd_seven.jpg';
import CustomSwiper from "../../conponents/CustomSwiper";

const PageKdThirty = () => {
    const data = [{src: kd_one}, {src: kd_two}, {src: kd_three}, {src: kd_four}, {src: kd_five}, {src: kd_six}, {src: kd_seven}]

    return <CustomSwiper data={data} isVideo={false}/>
}

export default PageKdThirty;
