import React, {useEffect, useRef, useState} from "react";
import 'swiper/css';
import '../../css/index.css';
import kd_one from "../../../assets/twenty/KdTwentyOne/kd_one.mp3";
import kd_two from "../../../assets/twenty/KdTwentyOne/kd_two.gif";
import kd_play from "../../../assets/images/play.png";
import kd_pause from "../../../assets/images/pause.png";

const PageKdTwentyOne = () => {
    const videoRef = useRef(null);
    const [status, setStatus] = useState('play')
    const [showBtn, setShowBtn] = useState(true)

    useEffect(() => {
        document.title = '圣诞'
        const pauseFun = () => setStatus('play');
        videoRef.current.addEventListener('pause', pauseFun);
        const playFun = () => setStatus('pause');
        videoRef.current.addEventListener('play', playFun);
        const endFun = () => setStatus('play');
        videoRef.current.addEventListener('ended', endFun);

        // 取消监听
        return () => {
            videoRef.current.removeEventListener('pause', pauseFun);
            videoRef.current.removeEventListener('play', playFun);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            videoRef.current.removeEventListener('ended', endFun);
        }
    }, [])

    useEffect(() => {
        if (status === 'play') return;
        let timeChange = setTimeout(() => {
            setShowBtn(false)
            clearTimeout(timeChange)
        }, 1000)
    }, [status]);

    return (
        <div className={'two-video-content'}>
            <audio ref={videoRef} autoPlay loop src={kd_one}/>
            {showBtn && status === 'play' &&
                <img src={kd_play} className={'twentyOne-swiper-play'} alt="kd_play"
                     onClick={() => videoRef.current.play()}/>}
            {showBtn && status === 'pause' &&
                <img src={kd_pause} className={'twentyOne-swiper-play'} alt="kd_pause"
                     onClick={() => videoRef.current.pause()}/>}
            <img src={kd_two} className={'page-swiper-img'} alt="kd_two" onClick={() => setShowBtn(true)}/>
        </div>
    );
}

export default PageKdTwentyOne;
