import React, {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import kd_play from "../../../assets/images/bofang.png";
import kd_pause from "../../../assets/images/zanting.png";

const CustomSwiperThree = (props) => {
    const {data, title = '锐歌百科', favicon, isVideo = true, isArrow = true} = props
    const videoRef = useRef(null);
    const videoBtnRef = useRef(0);
    const [swiperRef, setSwiperRef] = useState(null)
    const [status, setStatus] = useState('play')
    const [showBtn, setShowBtn] = useState(true)
    const [showBig, setShowBig] = useState(false)
    const [showPorter, setShowPorter] = useState(false)

    useEffect(() => {
        document.title = title;
        if(favicon){
            let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = favicon
            document.getElementsByTagName('head')[0].appendChild(link);
        }
        if (isVideo) {
            const pauseFun = () => {
                setShowPorter(false)
                setStatus('play');
            }
            videoRef.current.addEventListener('pause', pauseFun);
            const playFun = () => setStatus('pause');
            videoRef.current.addEventListener('play', playFun);
            const endFun = () => {
                setShowBig(false)
                setStatus('play');
                setShowBtn(true)
                setShowPorter(true)
            }
            videoRef.current.addEventListener('ended', endFun);
            return () => {
                videoRef.current.removeEventListener('pause', pauseFun);
                videoRef.current.removeEventListener('play', playFun);
                // eslint-disable-next-line react-hooks/exhaustive-deps
                videoRef.current.removeEventListener('ended', endFun);
            }
        }
    }, [isVideo, title])

    useEffect(() => {
        if (isVideo) {
            if (status === 'play') return;
            let timeChange = setTimeout(() => {
                setShowBtn(false)
                clearTimeout(timeChange)
            }, 1000)
        }
    }, [isVideo, status]);

    function onSlider(isNext) {
        isNext ? swiperRef.slideNext() : swiperRef.slidePrev()
    }

    function onVideo(isPlay, isClose) {
        setShowPorter(false)
        if (isPlay) {
            setShowBig(true)
            setStatus('pause');
            videoRef.current.play()
        } else {
            videoBtnRef.current && clearTimeout(videoBtnRef.current)
            if (isClose) {
                videoRef.current.pause()
                setShowBig(false)
                setStatus('play');
                setShowBtn(true)
                setShowPorter(true)
            } else {
                videoRef.current.pause()
            }
        }
    }

    function closeBtn() {
        setShowBtn(true)
        videoBtnRef.current = setTimeout(() => {
            setShowBtn(false)
            clearTimeout(videoBtnRef.current)
        }, 2500)
    }

    return (
        <Swiper autoHeight slidesPerView={1} resistanceRatio={0} onSwiper={(swiper) => setSwiperRef(swiper)}>
            {data.map((item, index) => (
                <SwiperSlide key={index} style={{position: 'relative'}}>
                    <img src={item.src} className={'page-swiper-img'} alt="图片"/>
                    {item.videoShow && <div className={showBig ? 'page-video-full' : item.videoClassName}>
                        <video
                            className={showBig ? 'page-video-fill' : 'page-swiper-img'}
                            playsInline
                            // controls
                            ref={videoRef}
                            poster={item.videoPoster}
                            src={item.videoSrc}
                            onClick={() => closeBtn()}
                        />
                        {showPorter && <img src={item.videoPoster} className={'page-video-poster'} alt="封面图"/>}
                        {showBtn && status === 'play' &&
                            <img src={kd_play} className={showBig ? 'page-video-play-full-two' : 'page-video-play-two'} alt="播放" onClick={() => onVideo(true)}/>}
                        {showBtn && status === 'pause' &&
                            <img src={kd_pause} className={showBig ? 'page-video-play-full-two' : 'page-video-play-two'} alt="暂停" onClick={() => onVideo()}/>}
                        {showBig &&
                            <img src={item.closeSrc} className={item.closeClassName} alt="关闭" onClick={() => onVideo(false, true)}/>}

                    </div>}
                    {index < data.length - 1 && isArrow &&
                        <div className={'page-right-arrow-sixtysix'} onClick={() => onSlider(true)}/>}
                    {index > 0 && isArrow &&
                        <div className={'page-left-arrow-sixtysix'}  onClick={() => onSlider()}/>}
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default CustomSwiperThree;
