import React, {useEffect} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectCreative} from "swiper";
import 'swiper/css';
import "swiper/css/effect-creative";
import '../../css/index.css';
import kd_one from '../../../assets/ten/KdFour/kd_one.png';
import kd_two from '../../../assets/ten/KdFour/kd_two.png';

const PageKdFour = () => {

    useEffect(() => {
        document.title = '上新了IQ'
    }, [])

    return (
        <Swiper
            className={'swiper'}
            grabCursor
            loop
            autoHeight
            effect={"creative"}
            creativeEffect={{
                prev: {
                    shadow: true,
                    origin: "left center",
                    translate: ["-5%", 0, -200],
                    rotate: [0, 100, 0],
                },
                next: {
                    origin: "right center",
                    translate: ["5%", 0, -200],
                    rotate: [0, -100, 0],
                },
            }}
            // creativeEffect={{
            //     prev: {
            //         shadow: true,
            //         origin: "left center",
            //         translate: ["-5%", 0, -200],
            //         rotate: [0, -180, 0],
            //     },
            //     next: {
            //         origin: "left center",
            //         translate: ["5%", 0, -200],
            //         rotate: [0, 180, 0],
            //     },
            // }}
            modules={[EffectCreative]}
        >
            <SwiperSlide><img src={kd_one} className={'page-swiper-img'} alt="kd_one"/></SwiperSlide>
            <SwiperSlide><img src={kd_two} className={'page-swiper-img'} alt="kd_two"/></SwiperSlide>
        </Swiper>
    );
}

export default PageKdFour;
