import React from "react";
import '../../css/index.css';
import kd_porter from '../../../assets/fifty/KdFiftyFive/kd_porter.png';
import kd_video from "../../../assets/fifty/KdFiftyFive/kd_mp4.mov";
import CustomVideo from "../../conponents/CustomVideo";

const PageKdFiftyFive = () => {

    return <CustomVideo kd_porter={kd_porter} kd_video={kd_video} title={'原神联名2'}/>
}

export default PageKdFiftyFive;
