import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/sixty/KdSixtyOne/kd_one.jpg';
import kd_two from '../../../assets/sixty/KdSixtyOne/kd_two.jpg';
import kd_three from '../../../assets/sixty/KdSixtyOne/kd_three.jpg';
import kd_video from "../../../assets/sixty/KdSixtyOne/kd_mp4.mp4";
import kd_poster from "../../../assets/sixty/KdSixtyOne/kd_poster.jpg";
import kd_close from "../../../assets/sixty/KdSixtyThree/kd_close.png";
import CustomSwiperTwo from "../../conponents/CustomSwiperTwo";

const PageKdSixtyThree = () => {
    const data = [{
        videoFill: true,
        src: kd_one,
        videoShow: true,
        videoPoster: kd_poster,
        videoSrc: kd_video,
        videoClassName: 'page-video-sixty',
        closeSrc: kd_close,
        closeClassName: 'page-video-close-sixtythree'
    }, {src: kd_two}, {src: kd_three}]

    return <CustomSwiperTwo data={data}/>
}

export default PageKdSixtyThree;
