import React, {useEffect} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectCreative} from "swiper";
import 'swiper/css';
import "swiper/css/effect-creative";
import '../../css/index.css';
import kd_one from '../../../assets/teen/KdEighteen/kd_one.jpg';
import kd_two from '../../../assets/teen/KdEighteen/kd_two.png';
import kd_three from '../../../assets/teen/KdEighteen/kd_three.png';
import kd_four from '../../../assets/teen/KdEighteen/kd_four.png';
import kd_five from '../../../assets/teen/KdEighteen/kd_five.png';
import kd_six from '../../../assets/teen/KdEighteen/kd_six.jpg';

const PageKdEighteen = () => {

    useEffect(() => {
        document.title = '国网充电'
    }, [])

    return (
        <Swiper autoHeight loop grabCursor effect={"creative"} modules={[EffectCreative]}
                creativeEffect={{
                    prev: {shadow: true, translate: ["-20%", 0, -1]},
                    next: {translate: ["100%", 0, 0]},
                }}>
            <SwiperSlide><img src={kd_one} className={'page-swiper-img'} alt="kd_two"/></SwiperSlide>
            <SwiperSlide><img src={kd_two} className={'page-swiper-img'} alt="kd_two"/></SwiperSlide>
            <SwiperSlide><img src={kd_three} className={'page-swiper-img'} alt="kd_three"/></SwiperSlide>
            <SwiperSlide><img src={kd_four} className={'page-swiper-img'} alt="kd_four"/></SwiperSlide>
            <SwiperSlide><img src={kd_five} className={'page-swiper-img'} alt="kd_five"/></SwiperSlide>
            <SwiperSlide><img src={kd_six} className={'page-swiper-img'} alt="kd_six"/></SwiperSlide>
        </Swiper>
    );
}

export default PageKdEighteen;
