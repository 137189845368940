import React, {useEffect} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import '../../css/index.css';
import kd_one from "../../../assets/twenty/KdTwentyThree/kd_two.jpg";

const PageKdTwentyThree = () => {
    useEffect(() => {
        document.title = '锐歌'
    }, [])
    return <Swiper autoHeight loop spaceBetween={10} slidesPerView={1} >
        <SwiperSlide><img src={kd_one} className={'page-swiper-img'} alt="kd_one"/></SwiperSlide>
        <SwiperSlide><img src={kd_one} className={'page-swiper-img'} alt="kd_one"/></SwiperSlide>
    </Swiper>
}

export default PageKdTwentyThree;
