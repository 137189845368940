/**
 * @desc: 凯迪活动页11
 */
 import React, {useEffect} from "react";
 import {Swiper, SwiperSlide} from 'swiper/react';
 import {EffectCreative} from "swiper";
 import 'swiper/css';
 import "swiper/css/effect-creative";
 import '../../css/index.css';
//  import bg_title from '../../assets/KdEleven/bg_title.png'
 import bg_left from '../../../assets/images/bg_left.png'
 import bg_left_arrow from '../../../assets/images/icon_left_arrow.png'
 import bg_right from '../../../assets/images/bg_right.png'
 import bg_right_arrow from '../../../assets/images/icon_right_arrow.png'
 import bg_one from '../../../assets/teen/KdEleven/bg_one.jpg';
 import bg_two from '../../../assets/teen/KdEleven/bg_two.jpg';
 import bg_three from '../../../assets/teen/KdEleven/bg_three.jpg';
 import bg_four from '../../../assets/teen/KdEleven/bg_four.jpg';
 import bg_five from '../../../assets/teen/KdEleven/bg_five.jpg';
 import bg_six from '../../../assets/teen/KdEleven/bg_six.jpg';

 const PageKdEleven = () => {

     useEffect(() => {
         document.title = '同道之选'
     }, [])

     return (
        <div className={'eleven-img-parent'}>
          <img src={bg_left} className={'eleven-left-img'} alt="left"/>
          <img src={bg_left_arrow} className={'eleven-left-img-arrow'} alt="left"/>
          <img src={bg_right} className={'eleven-right-img'} alt="left"/>
          <img src={bg_right_arrow} className={'eleven-right-img-arrow'} alt="left"/>
         <Swiper
             className={'swiper'}
             grabCursor
             loop
             autoHeight
             effect={"creative"}
             creativeEffect={{
                 prev: {
                     shadow: true,
                     origin: "left center",
                     translate: ["-5%", 0, -200],
                     rotate: [0, 100, 0],
                 },
                 next: {
                     origin: "right center",
                     translate: ["5%", 0, -200],
                     rotate: [0, -100, 0],
                 },
             }}
             modules={[EffectCreative]}
         >
             {/* <SwiperSlide><img src={bg_title} className={'page-swiper-img'} alt="kd_title"/></SwiperSlide> */}
             <SwiperSlide><img src={bg_two} className={'page-swiper-img'} alt="kd_two"/></SwiperSlide>
             <SwiperSlide><img src={bg_three} className={'page-swiper-img'} alt="kd_three"/></SwiperSlide>
             <SwiperSlide><img src={bg_four} className={'page-swiper-img'} alt="kd_four"/></SwiperSlide>
             <SwiperSlide><img src={bg_five} className={'page-swiper-img'} alt="kd_five"/></SwiperSlide>
             <SwiperSlide><img src={bg_six} className={'page-swiper-img'} alt="kd_six"/></SwiperSlide>
             <SwiperSlide><img src={bg_one} className={'page-swiper-img'} alt="kd_one"/></SwiperSlide>
         </Swiper>
        </div>
     );
 }

 export default PageKdEleven;
