import React, {useEffect} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import '../../css/index.css';
import kd_one from '../../../assets/thirty/KdThirtyOne/kd_one.jpg';
import kd_two from '../../../assets/thirty/KdThirtyOne/kd_two.jpg';
import kd_three from '../../../assets/thirty/KdThirtyOne/kd_three.jpg';
import kd_four from '../../../assets/thirty/KdThirtyOne/kd_four.jpg';
import kd_five from '../../../assets/thirty/KdThirtyOne/kd_five.jpg';
import kd_six from '../../../assets/thirty/KdThirtyOne/kd_six.jpg';

const PageKdThirtyOne = () => {

    useEffect(() => {
        document.title = '锐歌百科'
    }, [])

    return (
        <Swiper autoHeight slidesPerView={1}>
            <SwiperSlide><img src={kd_one} className={'page-swiper-img'} alt="kd_one"/></SwiperSlide>
            <SwiperSlide><img src={kd_two} className={'page-swiper-img'} alt="kd_two"/></SwiperSlide>
            <SwiperSlide><img src={kd_three} className={'page-swiper-img'} alt="kd_three"/></SwiperSlide>
            <SwiperSlide><img src={kd_four} className={'page-swiper-img'} alt="kd_four"/></SwiperSlide>
            <SwiperSlide><img src={kd_five} className={'page-swiper-img'} alt="kd_five"/></SwiperSlide>
            <SwiperSlide><img src={kd_six} className={'page-swiper-img'} alt="kd_six"/></SwiperSlide>
        </Swiper>
    );
}

export default PageKdThirtyOne;
