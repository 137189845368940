import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/seventy/KdSeventySeven/kd_one.jpg';
import kd_two from '../../../assets/seventy/KdSeventySeven/kd_two.jpg';
import kd_three from '../../../assets/seventy/KdSeventySeven/kd_three.jpg';
import kd_four from '../../../assets/seventy/KdSeventySeven/kd_four.jpg';
import kd_five from '../../../assets/seventy/KdSeventySeven/kd_five.jpg';
import kd_six from '../../../assets/seventy/KdSeventySeven/kd_six.jpg';
import CustomSwiperThree from "../../conponents/CustomSwiperThree";

const PageKdSeventySeven = () => {
    const data = [{src: kd_one}, {src: kd_two}, {src: kd_three}, {src: kd_four}, {src: kd_five}, {src: kd_six}]

    return <CustomSwiperThree data={data} isVideo={false} />
}

export default PageKdSeventySeven;
