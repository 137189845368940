import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/sixty/KdSixtySeven/kd_one.jpg';
import kd_two from '../../../assets/sixty/KdSixtySeven/kd_two.jpg';
import kd_three from '../../../assets/sixty/KdSixtySeven/kd_three.jpg';
import kd_video from "../../../assets/sixty/KdSixtySeven/kd_mp4.mp4";
import kd_poster from "../../../assets/sixty/KdSixtySeven/kd_poster.png";
import kd_close from "../../../assets/sixty/KdSixtyFour/kd_close.png";
import CustomSwiperTwo from "../../conponents/CustomSwiperTwo";

const PageKdSixtySeven = () => {
    const data = [{
        videoFill: true,
        src: kd_one,
        videoShow: true,
        videoPoster: kd_poster,
        videoSrc: kd_video,
        videoClassName: 'page-video-sixty',
        closeSrc: kd_close,
        closeClassName: 'page-video-close-sixtyone'
    }, {src: kd_two}, {src: kd_three}]

    return <CustomSwiperTwo data={data}/>
}

export default PageKdSixtySeven;
