import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/seventy/KdSeventyNine/kd_one.jpg';
import kd_two from '../../../assets/seventy/KdSeventyNine/kd_two.jpg';
import kd_three from '../../../assets/seventy/KdSeventyNine/kd_three.jpg';
import favicon from '../../../assets/seventy/KdSeventyNine/favicon.ico';
import CustomSwiperThree from "../../conponents/CustomSwiperThree";

const PageKdSeventyNine = () => {
    const data = [{src: kd_one}, {src: kd_two}, {src: kd_three}]

    return <CustomSwiperThree data={data} isVideo={false} title={'ikey手机钥匙'} favicon={favicon}/>
}

export default PageKdSeventyNine;
