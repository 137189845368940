import React from "react";
import '../../css/index.css';
import kd_porter from '../../../assets/fifty/KdFiftyThree/kd_porter.png';
import kd_video from "../../../assets/fifty/KdFiftyThree/kd_mp4.mov";
import CustomVideo from "../../conponents/CustomVideo";

const PageKdFiftyThree = () => {

    return <CustomVideo kd_porter={kd_porter} kd_video={kd_video} title={'CT6 120周年限量版'}/>
}

export default PageKdFiftyThree;
