import React, {useEffect} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import '../../css/index.css';
import "swiper/css/effect-creative";
import kd_one from '../../../assets/twenty/KdTwentySix/kd_one.jpg';
import kd_two from '../../../assets/twenty/KdTwentySix/kd_two.jpg';
import kd_three from '../../../assets/twenty/KdTwentySix/kd_three.jpg';
import kd_four from '../../../assets/twenty/KdTwentySix/kd_four.jpg';
import {EffectCreative} from "swiper";

const PageKdTwentySix = () => {

    useEffect(() => {
        document.title = 'OTA升级'
    }, [])

    return (
        <Swiper
            autoHeight
            spaceBetween={10}
            slidesPerView={1}
            effect={"creative"}
            modules={[EffectCreative]}
            creativeEffect={{
                prev: {
                    shadow: true,
                    origin: "left center",
                    translate: ["-5%", 0, -200],
                    rotate: [0, 100, 0],
                },
                next: {
                    origin: "right center",
                    translate: ["5%", 0, -200],
                    rotate: [0, -100, 0],
                },
            }}>
            <SwiperSlide><img src={kd_one} className={'page-swiper-img'} alt="kd_one"/></SwiperSlide>
            <SwiperSlide><img src={kd_two} className={'page-swiper-img'} alt="kd_two"/></SwiperSlide>
            <SwiperSlide><img src={kd_three} className={'page-swiper-img'} alt="kd_three"/></SwiperSlide>
            <SwiperSlide><img src={kd_four} className={'page-swiper-img'} alt="kd_four"/></SwiperSlide>
        </Swiper>
    );
}

export default PageKdTwentySix;
