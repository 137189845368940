import React, {useEffect} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectCreative} from 'swiper';
import 'swiper/css';
import "swiper/css/effect-creative";
import '../../css/index.css';
import kd_one from '../../../assets/teen/KdSeventeen/kd_one.jpg';
import kd_two from '../../../assets/teen/KdSeventeen/kd_two.jpg';
import kd_three from '../../../assets/teen/KdSeventeen/kd_three.jpg';
import kd_four from '../../../assets/teen/KdSeventeen/kd_four.jpg';
import kd_five from '../../../assets/teen/KdSeventeen/kd_five.jpg';

const PageKdSeventeen = () => {

    useEffect(() => {
        document.title = 'LYRIQ锐歌'
    }, [])

    return (
        <Swiper autoHeight loop grabCursor effect={"creative"} modules={[EffectCreative]}
                creativeEffect={{
                    prev: {shadow: true, translate: [0, 0, -400]},
                    next: {translate: ["100%", 0, 0]},
                }}>
            <SwiperSlide><img src={kd_one} className="page-swiper-img" alt={'kd_one'}/></SwiperSlide>
            <SwiperSlide><img src={kd_two} className="page-swiper-img" alt={'kd_two'}/></SwiperSlide>
            <SwiperSlide><img src={kd_three} className="page-swiper-img" alt={'kd_three'}/></SwiperSlide>
            <SwiperSlide><img src={kd_four} className="page-swiper-img" alt={'kd_four'}/></SwiperSlide>
            <SwiperSlide><img src={kd_five} className="page-swiper-img" alt={'kd_five'}/></SwiperSlide>
        </Swiper>
    );
}

export default PageKdSeventeen;


// import React, {useEffect} from "react";
// import {Swiper, SwiperSlide} from 'swiper/react';
// import {Lazy} from 'swiper';
// import 'swiper/css';
// import "swiper/css/lazy";
// import '../css/index.css';
// import kd_one from '../../assets/KdSeventeen/kd_two.jpg';
// import kd_two from '../../assets/KdSeventeen/kd_one.jpg';
// import kd_three from '../../assets/KdSeventeen/kd_three.jpg';
// import kd_four from '../../assets/KdSeventeen/kd_four.jpg';
// import kd_five from '../../assets/KdSeventeen/kd_five.jpg';
//
// const PageKdSeventeen = () => {
//
//     useEffect(() => {
//         document.title = 'LYRIQ锐歌'
//     }, [])
//
//     return (
//         <Swiper lazy autoHeight loop spaceBetween={10} slidesPerView={1} modules={[Lazy]}>
//             <SwiperSlide><img data-src={kd_one} className="swiper-lazy page-swiper-img" alt={'kd_one'}/></SwiperSlide>
//             <SwiperSlide><img data-src={kd_two} className="swiper-lazy page-swiper-img" alt={'kd_two'}/></SwiperSlide>
//             <SwiperSlide><img data-src={kd_three} className="swiper-lazy page-swiper-img" alt={'kd_three'}/></SwiperSlide>
//             <SwiperSlide><img data-src={kd_four} className="swiper-lazy page-swiper-img" alt={'kd_four'}/></SwiperSlide>
//             <SwiperSlide><img data-src={kd_five} className="swiper-lazy page-swiper-img" alt={'kd_five'}/></SwiperSlide>
//         </Swiper>
//     );
// }
//
// export default PageKdSeventeen;
//
