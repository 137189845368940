import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import '../../css/index.css';
import kd_one from '../../../assets/ten/KdSix/kd_one.png';
import kd_two from '../../../assets/ten/KdSix/kd_two.png';
import kd_one_bg from '../../../assets/ten/KdSix/kd_one_bg.png';
import kd_one_arrow from '../../../assets/ten/KdSix/kd_one_arrow.png';

const PageKdSix = () => {
    const [showBg, setShowBg] = useState(false);

    useEffect(() => {
        document.title='LYRIQ锐歌'
        let timeChange = setTimeout(() => {
            setShowBg(true)
            clearTimeout(timeChange)
        }, 1500)
    }, [])

    return (
        <Swiper autoHeight loop spaceBetween={10} slidesPerView={1}>
            <SwiperSlide>
                <img src={kd_one} className={'page-swiper-img'} alt="kd_one"/>
                <div className={'img-right-press'}>
                    {showBg && <img src={kd_one_bg} className={'swiper-bg'} alt="kd_one"/>}
                    {showBg && <img src={kd_one_arrow} className={'swiper-arrow'} alt="kd_one"/>}
                </div>
            </SwiperSlide>
            <SwiperSlide><img src={kd_two} className={'page-swiper-img'} alt="kd_two"/></SwiperSlide>
        </Swiper>
    );
}

export default PageKdSix;
