import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/thirty/KdThirtySeven/kd_one.jpg';
import kd_two from '../../../assets/thirty/KdThirtySeven/kd_two.jpg';
import kd_three from '../../../assets/thirty/KdThirtySeven/kd_three.jpg';
import kd_four from '../../../assets/thirty/KdThirtySeven/kd_four.jpg';
import kd_five from '../../../assets/thirty/KdThirtySeven/kd_five.jpg';
import CustomSwiper from "../../conponents/CustomSwiper";

const PageKdThirtySeven = () => {
    const data = [{src: kd_one}, {src: kd_two}, {src: kd_three}, {src: kd_four}, {src: kd_five}]

    return <CustomSwiper title={'升级2.0'} data={data} isVideo={false}/>
}

export default PageKdThirtySeven;
