import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectCreative} from "swiper";
import 'swiper/css';
import "swiper/css/effect-creative";
import '../../css/index.css';
import kd_one from '../../../assets/ten/KdFive/kd_one.png';
import kd_two from '../../../assets/ten/KdFive/kd_two.png';
import kd_one_bg from '../../../assets/ten/KdFive/kd_one_bg.png';
import kd_one_arrow from '../../../assets/ten/KdFive/kd_one_arrow.png';

const PageKdFive = () => {
    const [showBg, setShowBg] = useState(false);

    useEffect(() => {
        document.title = '秋日特饮指南'
        let timeChange = setTimeout(() => {
            setShowBg(true)
            clearTimeout(timeChange)
        }, 1500)
    }, [])

    return (
        <Swiper
            autoHeight
            loop
            grabCursor
            effect={"creative"}
            creativeEffect={{
                prev: {shadow: true, translate: ["-20%", 0, -1]},
                next: {translate: ["100%", 0, 0]},
            }}
            modules={[EffectCreative]}>
            <SwiperSlide>
                <img src={kd_one} className={'page-swiper-img'} alt="kd_one"/>
                <div className={'img-right-press'}>
                    {showBg && <img src={kd_one_bg} className={'swiper-bg'} alt="kd_one"/>}
                    {showBg && <img src={kd_one_arrow} className={'swiper-arrow'} alt="kd_one"/>}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <img src={kd_two} className={'page-swiper-img'} alt="kd_two"/>
            </SwiperSlide>
        </Swiper>
    );
}

export default PageKdFive;
