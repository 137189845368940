import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/fifty/KdFiftyEight/kd_one.jpg';
import kd_two from '../../../assets/fifty/KdFiftyEight/kd_two.jpg';
import kd_three from '../../../assets/fifty/KdFiftyEight/kd_three.jpg';
import kd_video from "../../../assets/fifty/KdFiftyEight/kd_mp4.mp4";
import kd_poster from "../../../assets/fifty/KdFiftyEight/kd_poster.png";
import CustomSwiper from "../../conponents/CustomSwiper";

const PageKdFiftyEight = () => {
    const data = [{
        src: kd_one,
        videoShow: true,
        videoPoster: kd_poster,
        videoSrc: kd_video,
        videoClassName: 'page-video-fiftyone'
    }, {src: kd_two}, {src: kd_three}]

    return <CustomSwiper data={data}/>
}

export default PageKdFiftyEight;
