import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/sixty/KdSixtyTwo/kd_one.png';
import kd_two from '../../../assets/sixty/KdSixtyTwo/kd_two.png';
import CustomSwiper from "../../conponents/CustomSwiper";

const PageKdSixtyTwo = () => {
    const data = [{src: kd_one}, {src: kd_two}]

    return <CustomSwiper data={data} isVideo={false} />
}

export default PageKdSixtyTwo;
