/**
 * @desc: 凯迪活动页12
 */
 import React, {useEffect} from "react";
 import 'swiper/css';
 import '../../css/index.css';
 import kd_first from '../../../assets/teen/KdTwelve/bg_first.jpg';

 const PageKdTwelve = () => {

     useEffect(() => {
         document.title = '冬季指南平安出行'
     }, [])

     return (
        <div className={'twelve-container'}>
          <img src={kd_first} className={'twelve-img-cover'} alt="kd_three"/>
        </div>
     );
 }

 export default PageKdTwelve;
