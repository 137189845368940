import React from "react";
import '../../css/index.css';
import kd_porter from '../../../assets/fifty/KdFiftyTwo/kd_porter.png';
import kd_video from "../../../assets/fifty/KdFiftyTwo/kd_mp4.mov";
import CustomVideo from "../../conponents/CustomVideo";

const PageKdFiftyTwo = () => {

    return <CustomVideo kd_porter={kd_porter} kd_video={kd_video} title={'凯迪拉克XT4原神联名版'}/>
}

export default PageKdFiftyTwo;
