import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/thirty/KdThirtyNine/kd_one.jpg';
import kd_poster from "../../../assets/thirty/KdThirtyNine/kd_porter.png";
import kd_video from "../../../assets/thirty/KdThirtyNine/kd_mp4.mp4";
import CustomSwiper from "../../conponents/CustomSwiper";
const PageKdThirtyNine = () => {
    const data = [{
        src: kd_one,
        videoShow: true,
        videoPoster: kd_poster,
        videoSrc: kd_video,
        videoClassName: 'page-video-thirtynine'
    }]

    return <CustomSwiper data={data}/>
}

export default PageKdThirtyNine;

