import React, {Suspense} from "react";
import {Routes, Route} from "react-router-dom";
import PageKdOne from "./pages/PageTen/PageKdOne";
import PageKdTwo from "./pages/PageTen/PageKdTwo";
import PageKdThree from "./pages/PageTen/PageKdThree";
import PageKdFour from "./pages/PageTen/PageKdFour";
import PageKdFive from "./pages/PageTen/PageKdFive";
import PageKdSix from "./pages/PageTen/PageKdSix";
import PageKdSeven from "./pages/PageTen/PageKdSeven";
import PageKdEight from "./pages/PageTen/PageKdEight";
import PageKdNine from "./pages/PageTen/PageKdNine";

import PageKdTen from "./pages/PageTen/PageKdTen";
import PageKdEleven from "./pages/PageTeen/PageKdEleven";
import PageKdTwelve from "./pages/PageTeen/PageKdTwelve";
import PageKdFifteen from "./pages/PageTeen/PageKdFifteen";
import PageKdSixteen from "./pages/PageTeen/PageKdSixteen";
import PageKdSeventeen from "./pages/PageTeen/PageKdSeventeen";
import PageKdEighteen from "./pages/PageTeen/PageKdEighteen";
import PageKdNineteen from "./pages/PageTeen/PageKdNineteen";

import PageKdTwenty from "./pages/PageTwenty/PageKdTwenty";
import PageKdTwentyOne from "./pages/PageTwenty/PageKdTwentyOne";
import PageKdTwentyTwo from "./pages/PageTwenty/PageKdTwentyTwo";
import PageKdTwentyThree from "./pages/PageTwenty/PageKdTwentyThree";
import PageKdTwentyFour from "./pages/PageTwenty/PageKdTwentyFour";
import PageKdTwentyFive from "./pages/PageTwenty/PageKdTwentyFive";
import PageKdTwentySix from "./pages/PageTwenty/PageKdTwentySix";
import PageKdTwentySeven from "./pages/PageTwenty/PageKdTwentySeven";
import PageKdTwentyEight from "./pages/PageTwenty/PageKdTwentyEight";
import PageKdTwentyNine from "./pages/PageTwenty/PageKdTwentyNine";

import PageKdThirty from "./pages/PageThirty/PageKdThirty";
import PageKdThirtyOne from "./pages/PageThirty/PageKdThirtyOne";
import PageKdThirtyTwo from "./pages/PageThirty/PageKdThirtyTwo";
import PageKdThirtyThree from "./pages/PageThirty/PageKdThirtyThree";
import PageKdThirtyFour from "./pages/PageThirty/PageKdThirtyFour";
import PageKdThirtyFive from "./pages/PageThirty/PageKdThirtyFive";
import PageKdThirtySix from "./pages/PageThirty/PageKdThirtySix";
import PageKdThirtySeven from "./pages/PageThirty/PageKdThirtySeven";
import PageKdThirtyEight from "./pages/PageThirty/PageKdThirtyEight";
import PageKdThirtyNine from "./pages/PageThirty/PageKdThirtyNine";

import PageKdForty from "./pages/PageForty/PageKdForty";
import PageKdFortyOne from "./pages/PageForty/PageKdFortyOne";
import PageKdFortyTwo from "./pages/PageForty/PageKdFortyTwo";
import PageKdFortyThree from "./pages/PageForty/PageKdFortyThree";
import PageKdFortyFour from "./pages/PageForty/PageKdFortyFour";
import PageKdFortyFive from "./pages/PageForty/PageKdFortyFive";
import PageKdFortySix from "./pages/PageForty/PageKdFortySix";
import PageKdFortySeven from "./pages/PageForty/PageKdFortySeven";
import PageKdFortyEight from "./pages/PageForty/PageKdFortyEight";
import PageKdFortyNine from "./pages/PageForty/PageKdFortyNine";

import PageKdFifty from "./pages/PageFifty/PageKdFifty";
import PageKdFiftyOne from "./pages/PageFifty/PageKdFiftyOne";
import PageKdFiftyTwo from "./pages/PageFifty/PageKdFiftyTwo";
import PageKdFiftyThree from "./pages/PageFifty/PageKdFiftyThree";
import PageKdFiftyFour from "./pages/PageFifty/PageKdFiftyFour";
import PageKdFiftyFive from "./pages/PageFifty/PageKdFiftyFive";
import PageKdFiftySix from "./pages/PageFifty/PageKdFiftySix";
import PageKdFiftySeven from "./pages/PageFifty/PageKdFiftySeven";
import PageKdFiftyEight from "./pages/PageFifty/PageKdFiftyEight";
import PageKdFiftyNine from "./pages/PageFifty/PageKdFiftyNine";

import PageKdSixty from "./pages/PageSixty/PageKdSixty";
import PageKdSixtyOne from "./pages/PageSixty/PageKdSixtyOne";
import PageKdSixtyTwo from "./pages/PageSixty/PageKdSixtyTwo";
import PageKdSixtyThree from "./pages/PageSixty/PageKdSixtyThree";
import PageKdSixtyFour from "./pages/PageSixty/PageKdSixtyFour";
import PageKdSixtyFive from "./pages/PageSixty/PageKdSixtyFive";
import PageKdSixtySix from "./pages/PageSixty/PageKdSixtySix";
import PageKdSixtySeven from "./pages/PageSixty/PageKdSixtySeven";
import PageKdSixtyEight from "./pages/PageSixty/PageKdSixtyEight";
import PageKdSixtyNine from "./pages/PageSixty/PageKdSixtyNine";

import PageKdSeventy from "./pages/PageSeventy/PageKdSeventy";
import PageKdSeventyOne from "./pages/PageSeventy/PageKdSeventyOne";
import PageKdSeventyTwo from "./pages/PageSeventy/PageKdSeventyTwo";
import PageKdSeventyThree from "./pages/PageSeventy/PageKdSeventyThree";
import PageKdSeventyFour from "./pages/PageSeventy/PageKdSeventyFour";
import PageKdSeventyFive from "./pages/PageSeventy/PageKdSeventyFive";
import PageKdSeventySix from "./pages/PageSeventy/PageKdSeventySix";
import PageKdSeventySeven from "./pages/PageSeventy/PageKdSeventySeven";
import PageKdSeventyNine from "./pages/PageSeventy/PageKdSeventyNine";

function App() {
    const routeArr = [
        {path: '/', element: <PageKdOne/>},
        {path: '/two', element: <PageKdTwo/>},
        {path: '/three', element: <PageKdThree/>},
        {path: '/four', element: <PageKdFour/>},
        {path: '/five', element: <PageKdFive/>},
        {path: '/six', element: <PageKdSix/>},
        {path: '/seven', element: <PageKdSeven/>},
        {path: '/eight', element: <PageKdEight/>},
        {path: '/nine', element: <PageKdNine/>},

        {path: '/ten', element: <PageKdTen/>},
        {path: '/eleven', element: <PageKdEleven/>},
        {path: '/twelve', element: <PageKdTwelve/>},
        {path: '/fifteen', element: <PageKdFifteen/>},
        {path: '/sixteen', element: <PageKdSixteen/>},
        {path: '/seventeen', element: <PageKdSeventeen/>},
        {path: '/eighteen', element: <PageKdEighteen/>},
        {path: '/nineteen', element: <PageKdNineteen/>},

        {path: '/twenty', element: <PageKdTwenty/>},
        {path: '/twentyone', element: <PageKdTwentyOne/>},
        {path: '/twentytwo', element: <PageKdTwentyTwo/>},
        {path: '/twentythree', element: <PageKdTwentyThree/>},
        {path: '/twentyfour', element: <PageKdTwentyFour/>},
        {path: '/twentyfive', element: <PageKdTwentyFive/>},
        {path: '/twentysix', element: <PageKdTwentySix/>},
        {path: '/twentyseven', element: <PageKdTwentySeven/>},
        {path: '/twentyeight', element: <PageKdTwentyEight/>},
        {path: '/twentynine', element: <PageKdTwentyNine/>},

        {path: '/thirty', element: <PageKdThirty/>},
        {path: '/thirtyone', element: <PageKdThirtyOne/>},
        {path: '/thirtytwo', element: <PageKdThirtyTwo/>},
        {path: '/thirtythree', element: <PageKdThirtyThree/>},
        {path: '/thirtyfour', element: <PageKdThirtyFour/>},
        {path: '/thirtyfive', element: <PageKdThirtyFive/>},
        {path: '/thirtysix', element: <PageKdThirtySix/>},
        {path: '/thirtyseven', element: <PageKdThirtySeven/>},
        {path: '/thirtyeight', element: <PageKdThirtyEight/>},
        {path: '/thirtynine', element: <PageKdThirtyNine/>},

        {path: '/forty', element: <PageKdForty/>},
        {path: '/fortyone', element: <PageKdFortyOne/>},
        {path: '/fortytwo', element: <PageKdFortyTwo/>},
        {path: '/fortythree', element: <PageKdFortyThree/>},
        {path: '/fortyfour', element: <PageKdFortyFour/>},
        {path: '/fortyfive', element: <PageKdFortyFive/>},
        {path: '/fortysix', element: <PageKdFortySix/>},
        {path: '/fortyseven', element: <PageKdFortySeven/>},
        {path: '/fortyeight', element: <PageKdFortyEight/>},
        {path: '/fortynine', element: <PageKdFortyNine/>},

        {path: '/fifty', element: <PageKdFifty/>},
        {path: '/fiftyone', element: <PageKdFiftyOne/>},
        {path: '/fiftytwo', element: <PageKdFiftyTwo/>},
        {path: '/fiftythree', element: <PageKdFiftyThree/>},
        {path: '/fiftyfour', element: <PageKdFiftyFour/>},
        {path: '/fiftyfive', element: <PageKdFiftyFive/>},
        {path: '/fiftysix', element: <PageKdFiftySix/>},
        {path: '/fiftyseven', element: <PageKdFiftySeven/>},
        {path: '/fiftyeight', element: <PageKdFiftyEight/>},
        {path: '/fiftynine', element: <PageKdFiftyNine/>},

        {path: '/sixty', element: <PageKdSixty/>},
        {path: '/sixtyone', element: <PageKdSixtyOne/>},
        {path: '/sixtytwo', element: <PageKdSixtyTwo/>},
        {path: '/sixtythree', element: <PageKdSixtyThree/>},
        {path: '/sixtyfour', element: <PageKdSixtyFour/>},
        {path: '/sixtyfive', element: <PageKdSixtyFive/>},
        {path: '/sixtysix', element: <PageKdSixtySix/>},
        {path: '/sixtyseven', element: <PageKdSixtySeven/>},
        {path: '/sixtyeight', element: <PageKdSixtyEight/>},
        {path: '/sixtynine', element: <PageKdSixtyNine/>},

        {path: '/seventy', element: <PageKdSeventy/>},
        {path: '/seventyone', element: <PageKdSeventyOne/>},
        {path: '/seventytwo', element: <PageKdSeventyTwo/>},
        {path: '/seventythree', element: <PageKdSeventyThree/>},
        {path: '/seventyfour', element: <PageKdSeventyFour/>},
        {path: '/seventyfive', element: <PageKdSeventyFive/>},
        {path: '/seventysix', element: <PageKdSeventySix/>},
        {path: '/seventyseven', element: <PageKdSeventySeven/>},
        {path: '/seventyeight', element: <PageKdSeventySeven/>},
        {path: '/seventynine', element: <PageKdSeventyNine/>},

        {path: '/eighty', element: <PageKdSeventyNine/>},
    ]

    return (
        <Suspense fallback={<></>}>{/*fallback 属性接受任何在组件加载过程中你想展示的 React 元素 懒加载组件是 <React.Suspense> 支持的唯一用例：*/}
            <Routes> {/* 只匹配一条路由*/}
                {routeArr.map((item, index) => <Route key={index} path={item.path} element={item.element}/>)}
            </Routes>
        </Suspense>
    );
}

export default App;
