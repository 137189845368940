import React, {useEffect} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import '../../css/index.css';
import kd_one from '../../../assets/teen/KdFifteen/kd_one.jpg';
import kd_two from '../../../assets/teen/KdFifteen/kd_two.jpg';
import kd_three from '../../../assets/teen/KdFifteen/kd_three.jpg';
import kd_four from '../../../assets/teen/KdFifteen/kd_four.jpg';

const PageKdFifteen = () => {

    useEffect(() => {
        document.title = '为你、逐光而来'
    }, [])

    return (
        <Swiper autoHeight loop spaceBetween={10} slidesPerView={1}>
            <SwiperSlide><img src={kd_one} className={'page-swiper-img'} alt="kd_one"/></SwiperSlide>
            <SwiperSlide><img src={kd_two} className={'page-swiper-img'} alt="kd_two"/></SwiperSlide>
            <SwiperSlide><img src={kd_three} className={'page-swiper-img'} alt="kd_three"/></SwiperSlide>
            <SwiperSlide><img src={kd_four} className={'page-swiper-img'} alt="kd_four"/></SwiperSlide>
        </Swiper>
    );
}

export default PageKdFifteen;
