import React, {useEffect, useState} from "react";
import '../../css/index.css';
import kd_one from '../../../assets/ten/KdNine/kd_one.png';
import kd_two from '../../../assets/ten/KdNine/kd_two.png';
import kd_three from '../../../assets/ten/KdNine/kd_three.png';
import kd_four from '../../../assets/ten/KdNine/kd_four.png';
import kd_five from '../../../assets/ten/KdNine/kd_five.png';

const PageKdNine = () => {
    const [ratio, setRatio] = useState(null);
    const imgData = [kd_one, kd_two, kd_three, kd_four, kd_five]

    const resizeUpdate = (e) => {
        let wdr = Math.round(e.target.innerWidth / 2400 * 1000) / 1000;
        setRatio(wdr)
    };

    useEffect(() => {
        document.title = '无感支付、自由出行'
        let wdr = Math.round(window.innerWidth / 2400 * 1000) / 1000;
        setRatio(wdr)
        window.addEventListener('resize', resizeUpdate);
        return () => {
            window.removeEventListener('resize', resizeUpdate);
        }
    }, [])

    useEffect(() => {
        ratio && onLoadImg(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ratio])

    function onLoadImg(key) {
        let imgDoc = document.getElementsByTagName("img")
        if (imgDoc[key].src || key > imgData.length - 1) return;
        imgDoc[key].src = imgData[key];
        if (key > 1) {
            const imgWidth = [7100, 10167, 11861]
            imgDoc[key].style.width = imgWidth[key - 2] * ratio + 'px';
        }
        imgDoc[key].onload = function () {
            console.log(key + '加载完成')
            onLoadImg(++key)
        }
    }

    return (
        <div className={'nine-box'}>
            {imgData.map((item, index) => (
                <img key={index} className={'page-swiper-img'} alt="kd_one"/>
            ))}
        </div>
    );
}

export default PageKdNine;

// import React, {useEffect, useState} from "react";
// import '../css/index.css';
// import kd_one from '../../assets/KdNine/kd_one.png';
// import kd_two from '../../assets/KdNine/kd_two.png';
// import kd_three from '../../assets/KdNine/kd_three.png';
// import kd_four from '../../assets/KdNine/kd_four.png';
// import kd_five from '../../assets/KdNine/kd_five.png';
//
// const PageKdNine = () => {
//     const [ratio, setRatio] = useState(null);
//
//
//     const resizeUpdate = (e) => {
//         let wdr = Math.round(e.target.innerWidth / 2400 * 1000) / 1000;
//         setRatio(wdr)
//     };
//
//     useEffect(() => {
//         document.title = '无感支付、自由出行'
//         let wdr = Math.round(window.innerWidth / 2400 * 1000) / 1000;
//         setRatio(wdr)
//         window.addEventListener('resize', resizeUpdate);
//         return () => {
//             window.removeEventListener('resize', resizeUpdate);
//         }
//     }, [])
//
//
//     return (
//         <div className={'nine-box'}>
//             <img src={kd_one} className={'page-swiper-img'} alt="kd_one" onLoad={()=>console.log('kd_one加载完成')} onError={()=>console.log('kd_one加载错误')}/>
//             <img src={kd_two} className={'page-swiper-img'} alt="kd_two" onLoad={()=>console.log('kd_two加载完成')} onError={()=>console.log('kd_one加载错误')}/>
//             <img src={kd_three} className={'nine-swiper-img'} style={{width: `${7100 * ratio}px`}} alt="kd_three" onLoad={()=>console.log('kd_three加载完成')} onError={()=>console.log('kd_one加载错误')}/>
//             <img src={kd_four} className={'nine-swiper-img'} style={{width: `${10167 * ratio}px`}} alt="kd_four" onLoad={()=>console.log('kd_four加载完成')} onError={()=>console.log('kd_one加载错误')}/>
//             <img src={kd_five} className={'nine-swiper-img'} style={{width: `${11861 * ratio}px`}} alt="kd_five" onLoad={()=>console.log('kd_five加载完成')} onError={()=>console.log('kd_one加载错误')}/>
//         </div>
//     );
// }
//
// export default PageKdNine;
