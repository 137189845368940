import React, {useEffect} from "react";
import 'swiper/css';
import '../../css/index.css';
import kd_two from "../../../assets/teen/KdNineteen/kd_two.gif";

const PageKdNineteen = () => {

    useEffect(() => {
        document.title = '圣诞'
    }, [])

    return (
        <div><img src={kd_two} className={'page-swiper-img'} alt="kd_one"/></div>
    );
}

export default PageKdNineteen;
