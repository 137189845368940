import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/twenty/KdTwentyNine/kd_one.jpg';
import kd_two from '../../../assets/twenty/KdTwentyNine/kd_two.jpg';
import kd_poster from '../../../assets/twenty/KdTwentyNine/kd_porter.jpg';
import kd_video from "../../../assets/twenty/KdTwentyNine/kd_mp4.mp4";
import CustomSwiper from "../../conponents/CustomSwiper";

const PageKdTwentyNine = () => {
    const data = [{
        src: kd_one,
        videoShow: true,
        videoPoster: kd_poster,
        videoSrc: kd_video,
        videoClassName: 'page-video-twentynine'
    }, {src: kd_two}]

    return <CustomSwiper data={data}/>
}

export default PageKdTwentyNine;
