import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/sixty/KdSixtyNine/kd_one.jpg';
import kd_two from '../../../assets/sixty/KdSixtyNine/kd_two.jpg';
import CustomSwiper from "../../conponents/CustomSwiper";

const PageKdSixtyNine = () => {
    const data = [{src: kd_one}, {src: kd_two}]

    return <CustomSwiper data={data} isVideo={false} />
}

export default PageKdSixtyNine;
