import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/fifty/KdFiftySix/kd_one.jpg';
import kd_two from '../../../assets/fifty/KdFiftySix/kd_two.jpg';
import kd_three from '../../../assets/fifty/KdFiftySix/kd_three.jpg';
import kd_four from '../../../assets/fifty/KdFiftySix/kd_four.jpg';
import kd_five from '../../../assets/fifty/KdFiftySix/kd_five.jpg';
import kd_six from '../../../assets/fifty/KdFiftySix/kd_six.jpg';
import kd_video from "../../../assets/fifty/KdFiftySix/kd_mp4.mp4";
import kd_poster from "../../../assets/fifty/KdFiftySix/kd_poster.jpg";
import CustomSwiper from "../../conponents/CustomSwiper";

const PageKdFiftySix = () => {
    const data = [{
        src: kd_one,
        videoShow: true,
        videoPoster: kd_poster,
        videoSrc: kd_video,
        videoClassName: 'page-video-twentyseven'
    }, {src: kd_two}, {src: kd_three}, {src: kd_four}, {src: kd_five}, {src: kd_six}]

    return <CustomSwiper data={data}/>
}

export default PageKdFiftySix;
