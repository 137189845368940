import React, {useEffect} from "react";
import '../../css/index.css';
import kd_one from '../../../assets/twenty/KdTwenty/kd_one.m4v';

const PageKdTwenty = () => {

    useEffect(() => {
        document.title = '圣诞'
    }, [])

    return (
        <div className={'two-video-content'}>
            <video autoPlay muted loop playsInline style={{display: 'block'}} width={'100%'} height={'100%'} src={kd_one}/>
        </div>

    );
}

export default PageKdTwenty;
