import React from "react";
import '../../css/index.css';
import kd_one from '../../../assets/forty/KdFortySeven/kd_one.jpg';
import kd_two from '../../../assets/forty/KdFortySeven/kd_two.jpg';
import kd_video from "../../../assets/forty/KdFortySeven/kd_mp4.mp4";
import kd_poster from "../../../assets/forty/KdFortySeven/kd_poster.jpg";
import CustomSwiper from "../../conponents/CustomSwiper";

const PageKdFortySeven = () => {
    const data = [{
        src: kd_one,
        videoShow: true,
        videoPoster: kd_poster,
        videoSrc: kd_video,
        videoClassName: 'page-video-twentyseven'
    }, {src: kd_two}]

    return <CustomSwiper data={data}/>
}

export default PageKdFortySeven;
