import React, {useEffect, useRef, useState} from "react";
import 'swiper/css';
import '../../css/index.css';
import "swiper/css/effect-creative";
import kd_one from "../../../assets/twenty/KdTwentyTwo/kd_one.jpg";
import kd_two from "../../../assets/twenty/KdTwentyTwo/kd_two.jpg";
import kd_one_video from "../../../assets/twenty/KdTwentyTwo/kd_one_video.mp4";
import kd_play from "../../../assets/images/play.png";
import kd_pause from "../../../assets/images/pause.png";
import kd_preview from '../../../assets/twenty/KdTwentyTwo/preview.png';
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCreative} from "swiper";

const PageKdTwentyTwo = () => {
    const videoRef = useRef(null);
    const [status, setStatus] = useState('play')
    const [showBtn, setShowBtn] = useState(true)

    useEffect(() => {
        document.title = '雨刷'
        const pauseFun = () => setStatus('play');
        videoRef.current.addEventListener('pause', pauseFun);
        const playFun = () => setStatus('pause');
        videoRef.current.addEventListener('play', playFun);
        const endFun = () => setStatus('play');
        videoRef.current.addEventListener('ended', endFun);

        // 取消监听
        return () => {
            videoRef.current.removeEventListener('pause', pauseFun);
            videoRef.current.removeEventListener('play', playFun);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            videoRef.current.removeEventListener('ended', endFun);
        }
    }, [])

    useEffect(() => {
        if (status === 'play') return;
        let timeChange = setTimeout(() => {
            setShowBtn(false)
            clearTimeout(timeChange)
        }, 1000)
    }, [status]);

    return (
        <Swiper
            autoHeight
            spaceBetween={10}
            slidesPerView={1}
            effect={"creative"}
            modules={[EffectCreative]}
            creativeEffect={{
                prev: {
                    shadow: true,
                    origin: "left center",
                    translate: ["-5%", 0, -200],
                    rotate: [0, 100, 0],
                },
                next: {
                    origin: "right center",
                    translate: ["5%", 0, -200],
                    rotate: [0, -100, 0],
                },
            }}>
            <SwiperSlide style={{position: 'relative'}}>
                <img src={kd_one} className={'page-swiper-img'} alt="kd_one"/>
                <video ref={videoRef} playsInline onClick={() => setShowBtn(true)}
                       poster={kd_preview} className={'twentytwo_video'} src={kd_one_video}/>
                {showBtn && status === 'play' &&
                    <img src={kd_play} className={'twentytwo-swiper-play'} alt="kd_play"
                         onClick={() => videoRef.current.play()}/>}
                {showBtn && status === 'pause' &&
                    <img src={kd_pause} className={'twentytwo-swiper-play'} alt="kd_pause"
                         onClick={() => videoRef.current.pause()}/>}
            </SwiperSlide>
            <SwiperSlide><img src={kd_two} className={'page-swiper-img'} alt="kd_two"/></SwiperSlide>
        </Swiper>
    )
}

export default PageKdTwentyTwo;
