import React, {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import kd_play from "../../../assets/images/play.png";
import kd_pause from "../../../assets/images/pause.png";
import left_arrow from '../../../assets/images/left_arrow.gif';
import right_arrow from '../../../assets/images/right_arrow.gif';

const CustomSwiper = (props) => {
    const {data, title = '锐歌百科', isVideo = true} = props
    const videoRef = useRef(null);
    const videoBtnRef = useRef(0);
    const [swiperRef, setSwiperRef] = useState(null)
    const [status, setStatus] = useState('play')
    const [showBtn, setShowBtn] = useState(true)
    const [showBig, setShowBig] = useState(false)
    const [showPorter, setShowPorter] = useState(false)

    useEffect(() => {
        document.title = title;
        if (isVideo) {
            const pauseFun = () => {
                setShowPorter(false)
                setStatus('play');
            }
            videoRef.current.addEventListener('pause', pauseFun);
            const playFun = () => setStatus('pause');
            videoRef.current.addEventListener('play', playFun);
            const endFun = () => {
                setShowBig(false)
                setStatus('play');
                setShowBtn(true)
                setShowPorter(true)
            }
            videoRef.current.addEventListener('ended', endFun);
            return () => {
                videoRef.current.removeEventListener('pause', pauseFun);
                videoRef.current.removeEventListener('play', playFun);
                // eslint-disable-next-line react-hooks/exhaustive-deps
                videoRef.current.removeEventListener('ended', endFun);
            }
        }
    }, [isVideo, title])

    useEffect(() => {
        if (isVideo) {
            if (status === 'play') return;
            let timeChange = setTimeout(() => {
                setShowBtn(false)
                clearTimeout(timeChange)
            }, 1000)
        }
    }, [isVideo, status]);

    function onSlider(isNext) {
        isNext ? swiperRef.slideNext() : swiperRef.slidePrev()
    }

    function onVideo(isPlay) {
        setShowPorter(false)
        if (isPlay) {
            setShowBig(true)
            setStatus('pause');
            videoRef.current.play()
        } else {
            videoBtnRef.current && clearTimeout(videoBtnRef.current)
            setShowBig(false)
            videoRef.current.pause()
        }
    }

    function closeBtn() {
        setShowBtn(true)
        videoBtnRef.current = setTimeout(() => {
            setShowBtn(false)
            clearTimeout(videoBtnRef.current)
        }, 2500)
    }

    // function redirectToApp() {
    //     try {
    //         // 尝试执行跳转逻辑
    //         window.location.href = 'app_scheme://path?params=value';
    //     } catch (error) {
    //         // 处理跳转错误
    //         console.error('跳转失败：', error);
    //         // 提供错误提示信息或备用跳转方式
    //         // ...
    //     }
    // }

    return (
        <Swiper autoHeight slidesPerView={1} onSwiper={(swiper) => setSwiperRef(swiper)}>
            {data.map((item, index) => (
                <SwiperSlide key={index} style={{position: 'relative'}}>
                    <img src={item.src} className={'page-swiper-img'} alt="图片"/>
                    {item.videoShow && <div className={showBig ? 'page-video-full' : item.videoClassName}>
                        <video
                            className={'page-swiper-img'}
                            playsInline
                            // controls
                            ref={videoRef}
                            poster={item.videoPoster}
                            src={item.videoSrc}
                            onClick={() => closeBtn()}
                        />
                        {showPorter && <img src={item.videoPoster} className={'page-video-poster'} alt="封面图"/>}
                        {showBtn && status === 'play' &&
                            <img src={kd_play} className={'page-video-play'} alt="播放" onClick={() => onVideo(true)}/>}
                        {showBtn && status === 'pause' &&
                            <img src={kd_pause} className={'page-video-pause'} alt="暂停" onClick={() => onVideo()}/>}
                    </div>}
                    {index < data.length - 1 &&
                        <img src={right_arrow} className={'page-right-arrow'} alt="右箭头"
                             onClick={() => onSlider(true)}/>}
                    {index > 0 &&
                        <img src={left_arrow} className={'page-left-arrow'} alt="左箭头" onClick={() => onSlider()}/>}

                    {/*{item.showBtn && <div style={{*/}
                    {/*    display: 'flex',*/}
                    {/*    alignItems: 'center',*/}
                    {/*    justifyContent: 'center',*/}
                    {/*    position: 'absolute',*/}
                    {/*    right: '20%',*/}
                    {/*    bottom: '27%',*/}
                    {/*    zIndex: 10,*/}
                    {/*}}>*/}
                    {/*    <a href="clientapp://czjn:8080/news?tz=车主锦囊&url_parameter=新手指引&state=2">点击车主锦囊app</a>*/}
                    {/*</div>}*/}
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default CustomSwiper;
